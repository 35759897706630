@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  font-family: "Mulish", sans-serif !important;
}

.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: green!important;
  --rdp-background-color: green!important;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: green!important;
  --rdp-background-color-dark: green!important;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid green!important;
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid green!important;
}

.rdpWrapper{
  left: 0!important;
}

.bg-gradient {
  background: linear-gradient(
    135deg,
    rgba(0, 97, 215, 1) 0%,
    rgba(0, 200, 255, 1) 100%
  );
}

.pd-appinfo-outer {
  display: flex;
}

.pd-dashboard-padding {
  padding: 30px 0 0 30px;
}

.pd-appinfo-outer img {
  margin-right: 3em;
}

.pd-appinfo-inner {
  margin-right: 2em;
}

.pd-appinfo-inner select {
  box-sizing: content-box;
  padding: 0.5em 0 0.5em 0;
  /* background: #cae7d2; */
  border-radius: 10px;
  width: 120px;
  margin: 0 10px;
  font-size: 13px;
}

.pd-appinfo-inner label {
  font-size: 15px;
  margin-right: 0.2em;
}

.pd-appinfo-inner select option {
  padding: 10px 5px 10px 7px;
}

.pd-gender-div {
  display: grid;
}

.pd-appinfo-outer-main button {
  position: relative;
  background: #51ae66;
  color: #fff;
  width: 120px;
  margin-top: 10px;
  float: right;
  right: 60px;
  font-size: 0.9em;
  padding: 5px 0;
  border-radius: 5px;
}

.pd-table {
  margin-top: 60px;
  border-top: 6px solid #f7fafc;
}

.pd-gray-box {
  width: 12px;
  height: 12px;
  color: #e6e6e6;
  background: #e6e6e6;
  margin-right: 20px;
}

.pd-table th,
td {
  font-size: 0.8rem;
}

.pd-table th {
  font-weight: 600;
}

.pd-table td {
  font-weight: 350;
}

.pd-record-download img {
  margin-right: 20px;
}

.pd-excel {
  align-items: center;
}

.pd-record-download {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.pd-rec {
  display: flex;
  justify-content: space-between;
}

.pd-pagination {
  display: flex;
  justify-self: center;
}

.pd-record-download-pagination {
  display: flex;
  align-items: center;
}

.send-email-button {
  position: relative;
  float: right;
  margin-right: 20px;
}

/* .pd-record-download-pagination div{
    background: red;
    border-radius: 50%;
    margin: 0 15px;
    padding: 15px;
} */

.email-wrapper {
  display: grid;
  grid-template-rows: 90% 10%;
  height: 100%;
}

.email-details {
  margin: 0 20px;
}

.send-mail-header {
  background: #51ae66;
  padding: 25px;
  color: #fff !important;
}

.send-mail-to {
  display: grid;
  grid-template-columns: 10% 90%;
  padding: 20px 0;
  align-items: center;
}

.emailids {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5px;
  row-gap: 5px;
}

.emailids span {
  display: flex;
  justify-content: space-between;
  border: 1px solid #51ae66;
  border-radius: 15px;
  padding: 7px;
}

.email-body {
  margin-top: 20px;
}

.rating > span:hover:before {
  content: "\2605";
  position: absolute;
}

.rating {
  unicode-bidi: bidi-override;
  direction: rtl;
}
.rating > span:hover:before,
.rating > span:hover ~ span:before {
  content: "\2605";
  position: absolute;
}

.rating {
  border: 0;
  outline: none;
  unicode-bidi: bidi-override;
  direction: rtl;
}

.rating:focus {
  border: 0;
  outline: none;
}

.rating > span {
  display: inline-block;
  position: relative;
  width: 1.1em;
}
.rating > span:hover:before,
.rating > span:hover ~ span:before {
  content: "\2605";
  position: absolute;
  color: gold;
}

.rating:active > span:active:before,
.rating:active > span:active ~ span:before {
  color: gold;
  background-color: red;
}
.rating.active > span:before,
.rating > span ~ span:before {
  /* background:gold; */
  /* color: gold; */
  /* color: gold; */
  /* background-color: red; */
}

.rate {
  float: left;
  height: 46px;
  padding: 0 10px;
}
.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}
.rate:not(:checked) > label:before {
  content: "★ ";
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

/* //checkbox */

.checker {
  position: relative;
  top: 2px;
  width: 15px !important;
  height: 15px !important;
  border: 1px solid #000 !important;
  margin-right: 1rem;
  appearance: checkbox !important;
}
.menu_icons {
  /* position: relative; */
}

.menu_icons .icon_dot {
  /* position: relative; */
  width: 2px;
  height: 2px;
  background-color: #21334f;
  margin: 2px 0;
  color: #f5f5f5;
  z-index: 999;
}

.menu_icons .circle {
  position: absolute;
  top: 22px;
  right: -4px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #f5f5f5;
  z-index: 9;
  display: none;
}

.comment_btn {
  padding: 0.5rem;
  color: #fff;
  background: #51ae66;
}

.option-form {
  padding: 1rem !important;
  margin: 0 !important;
}
.ant-select-selector {
  width: 200px !important;
}

.option-button {
  display: inline-block;
  margin: 1rem !important;
  background-color: #48bb78 !important;
  color: #fff !important;
}
