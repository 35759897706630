.top-bar-gradient {
  height: 4px;
  background: linear-gradient(
    318deg,
    rgba(176, 0, 171, 1) 0%,
    rgba(143, 140, 255, 1) 100%
  );
}

.opacity-10 {
  opacity: 0.1;
}

.nato-sans {
  font-family: 'Noto Sans JP', sans-serif;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 10px;
  height: 10px;
  border: 10px solid green; /* Light grey */
  border-top: 10px solid white; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}